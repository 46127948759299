import { render, staticRenderFns } from "./BudgetPieChart.vue?vue&type=template&id=140b5133&scoped=true&"
import script from "./BudgetPieChart.vue?vue&type=script&lang=js&"
export * from "./BudgetPieChart.vue?vue&type=script&lang=js&"
import style0 from "./BudgetPieChart.vue?vue&type=style&index=0&id=140b5133&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "140b5133",
  null
  
)

export default component.exports