<template>
  <div class="flex-row">
    <div>
      <h1>Budget</h1>
      <vc-donut
        background="var(--background-primary)" 
        foreground="var(--background-secondary)"
        :size="145" unit="px" :thickness="30"
        :sections="[{
          color: '#6d4aff',
          value: Math.min(budget.amount_spent, budget.amount_budgeted)
        }]" 
        :total="budget.amount_budgeted"
        :start-angle="0" :auto-adjust-text-size="true">
        <h2>{{formatPercent(budget.amount_spent / budget.amount_budgeted)}}</h2>
      </vc-donut>
    </div>
    
    <div>
      <table class="legend-table">
        <tr>
          <td class="font-bold">Budgeted</td>
          <td>{{formatMoney(budget.amount_budgeted / 100)}}</td>
        </tr>
        <tr>
          <td class="font-bold">Spent</td>
          <td>{{formatMoney(budget.amount_spent / 100)}}</td>
        </tr>
        <tr>
          <td class="font-bold">Remaining</td>
          <td>{{formatMoney((budget.amount_budgeted - budget.amount_spent) / 100)}}</td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>

import FormatUtils from '../../utilities/FormatUtils'

export default {
  mixins: [FormatUtils],

  props: {
    budget: Object,
  },

  computed: {
  }

}
</script>

<style scoped>

.legend-table{
  margin: 8px;
}

.legend-table td{
  padding: 7px 10px;
}

</style>
