<template>
  <div>
      <NotificationPopup ref='popup'></NotificationPopup>

      <Modal ref="confirmDeleteModal" :title="'Delete Transactions'"  @submit='deleteTransactions'
              :submitLabel="'Delete'" :submitButtonClass="'btn btn-red'">
        <div class='delete-confirm-text'>Are you sure you want to delete these transactions?</div>
      </Modal>

      <div>

          <div class="header-container">
            <h1>Transactions</h1>
            <div class="add-button-container">
              <button class="btn btn-large btn-primary" @click="$emit('add-new-transaction')">New Transaction</button>
            </div>
          </div>
          
          <Table ref="table" itemKey='id'
              :itemsPerPage='14'
              :pagination='true'
              @delete-items='showDeleteConfirm'
              @refresh='loadTransactions'>

            <!-- FILTER CONENT -->
            <FormInputDate label="Start" ref="startDateInput"></FormInputDate> 
            <FormInputDate label="End" ref="endDateInput"></FormInputDate>
            <FormInputText label="Memo" ref="memoInput" :placeholder="'search'" class="memo-input"></FormInputText>
            <FormInputSelect label="Category" ref="categoryInput" :options="categoryOptions"></FormInputSelect>
            <FormInputSelect label="Sub-Category" ref="subCategoryInput" :options="subCategoryOptions"></FormInputSelect>
            <div class='align-bottom'>
              <button class="btn btn-secondary" @click='setDefaultFilterValues'>Reset</button>
            </div>
            <div class='align-bottom'>
              <button class="btn btn-primary" @click='loadTransactions'>Apply Filter</button>
            </div>
          </Table>
          <h3 class='total-row'> Total: {{ total() }} </h3>
      </div>

  </div>
</template>

<script>

import Table from '../components/Table'
import Modal from '../components/Modal'
import FormInputText from '../components/FormInputText'
import FormInputSelect from '../components/FormInputSelect'
import FormInputDate from '../components/FormInputDate'
import NotificationPopup from '../components/NotificationPopup'

import ServiceUtils from '../utilities/ServiceUtils'
import FormatUtils from '../utilities/FormatUtils'

export default {
  mixins: [ServiceUtils, FormatUtils],


  components: {
    Table,
    Modal,
    FormInputText,
    FormInputSelect,
    FormInputDate,
    NotificationPopup,
  },


  data() {
    return {
      transactions: [],
      categoryOptions: [],
      subCategoryOptions: [],
    }
  },

  mounted: function() {
    this.buildTable();
    this.setDefaultFilterValues();
    this.loadCategoryFilterOptions();
    this.loadTransactions();
  },

  methods: {

    total: function(){
      if(this.transactions && this.transactions.length > 0){
        var total_spent = this.transactions.map(t => t.amount).reduce((a,b) => a+b) / 100;
        return this.formatMoney(total_spent)
      }
      return 0;
    },

    setFilterOptions: function(filterOptions){
      this.$refs.startDateInput.setValue(filterOptions.start_date);
      this.$refs.endDateInput.setValue(filterOptions.end_date);
      this.$refs.categoryInput.setValue(filterOptions.category);
      this.$refs.subCategoryInput.setValue(filterOptions.sub_category);
    },

    loadCategoryFilterOptions: function() {
      this.get('/transactions/category-options', {}, 
      function(categoryOptions){
        this.categoryOptions = categoryOptions.categories;
        this.subCategoryOptions = categoryOptions.sub_categories;
      }.bind(this), function(error){
        this.$refs.popup.open("Error loading category filter options", true);
        console.log(error)
      }.bind(this))
    },
    
    setDefaultFilterValues: function() {
      var end_date = new Date();
      var start_date = new Date();
      start_date.setMonth(start_date.getMonth() - 1);

      this.setFilterOptions({
        'start_date': start_date,
        'end_date': end_date,
        category: "(any)",
        sub_category: "(any)"
      })
    },

    loadTransactions: function(){
      var queryParams = {
        'start_date': this.$refs.startDateInput.getValue(), 
        'end_date': this.$refs.endDateInput.getValue(), 
      }
      var category = this.$refs.categoryInput.getValue();
      if(category != "(any)" && category){
        queryParams["category"] = category;
      }
      var sub_category = this.$refs.subCategoryInput.getValue();
      if(sub_category != "(any)" && category) {
        queryParams["sub_category"] = sub_category;
      }

      var memo = this.$refs.memoInput.getValue()
      if (memo) {
        queryParams["memo"] = memo
      }

      this.get('/transactions', queryParams, 
      function(transactions){
        this.transactions = transactions;
        this.$refs.table.items = this.transactions;
        this.$refs.table.page = 1
      }.bind(this), function(error){
        this.$refs.popup.open("Could not load transactions", true);
        console.log(error)
      }.bind(this))
    },

    showDeleteConfirm: function(){
      this.$refs.confirmDeleteModal.open();
    },

    showAddTransactionModal: function(){
      this.$refs.addTransactionModal.open();
    },

    deleteTransactions: function(){
      let selected_ids = this.$refs.table.selected;

      for (var id of selected_ids) {
        this.delete('/transactions/' + id, function(){
          this.$refs.popup.open("Transaction(s) deleted.", false);
          this.$refs.confirmDeleteModal.close();
          this.$refs.table.deselectAll();
          this.loadTransactions();
        }.bind(this), function(error){
          this.$refs.popup.open("Error deleting transactions.", true);
          console.log("Error Deleting transaction", error)
        }.bind(this))
      }
    },
    
    buildTable: function(){

      if (this.$refs.table){
        this.$refs.table.columns = [
          { label: "Date", attr: "date", noWrap: true},
          { label: "Memo", attr: "memo" },
          { label: "Category", attr: "category", hideOnMobile: true},
          { label: "Sub-Category", attr: "sub_category", hideOnMobile: true},
          { label: "Amount", attr: "amount", display: "money"}
        ];
  
        this.$refs.table.items = this.transactions;
      }

    }

  }

}
</script>

<style scoped>

.header-container{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: baseline;
}

.add-button-container{
  margin: 0.67em 0;
}

.delete-confirm-text{
  margin-top: 1.0em;
  margin-bottom: 1.67em;
}

.align-bottom{
  margin-top: auto;
}

.total-row{
  display: flex;
  justify-content: flex-start;
  padding-right: 2em;
  margin-bottom: 4em;
}

.memo-input{
  width: 12em;
}

</style>
