<template>
    <div>
        <div v-if="isMobile" class="mobile-side-nav-container">
            <div v-for="item in navigationItems" :key="item.id" class="mobile-nav-item"
                :class="{'selected': itemIsSelcted(item)}"
                @click="itemSelected(item)">
                <i :class="item.icon"></i>
            </div>
        </div>

        <div v-if="!isMobile" class="side-nav-container side-nav-color font-primary" 
            :class="{'minimized': minimized, 'not-minimized': !minimized}">
            
            <div class="flex-center">
                <img v-show="!minimized" src="../assets/img/bdgt.site-logo-sm.png" 
                    alt="bdgt.site" width="150" class="logo-image">
                <img v-show="minimized" src="../assets/img/bdgt.site-logo-sm-logo-only.png" 
                    alt="bdgt.site" width="53" class="logo-image">
            </div>


            <div v-for="item in navigationItems" :key="item.id" class="nav-item flex-row flex-no-wrap"
                :class="{'selected': itemIsSelcted(item), 'nav-item-minimized': minimized}"
                @click="itemSelected(item)">

                <div>
                    <i :class="item.icon"></i>
                </div>
                <div v-if="!minimized" class="nav-label">
                    {{ item.label }}
                </div>
            </div>

        </div>      
    </div>


    
</template>

<script>

export default {

  props: {
      navigationItems: Array, 
      intialSelected: String,
      
  },

  data() {
    return {
        selected: this.intialSelected,
        minimized: false,
        isMobile: false,
    }
  },

  mounted() {
    this.checkMobile();
    window.addEventListener('resize', this.checkMobile);
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.checkMobile);
  },

  methods: {
      setSelected: function(item_label) {
        this.selected = item_label;
      },

      itemSelected: function(item) {
          this.selected = item.label;
          this.$emit('item-selected', item.label);
      },

      itemIsSelcted: function(item){
          return this.selected === item.label;
      },

      toggle: function(){
        this.minimized = !this.minimized;
      },

      checkMobile: function() {
        this.isMobile = window.innerWidth < 600;
      }
  },

  computed: {
    isMobile: function(){
      return window.innerWidth < 600;
    }
  }
}
</script>

<style scoped>

.selected, .selected:hover{
    background-color: var(--primary-color) !important;
    color: var(--font-light) !important;
}
.minimized{
    width: 4.5em;
    transition: width 0.2s ease-in-out;
    
}
.not-minimized{
    width: 12em;
    transition: width 0.2s ease-in-out;
}
.side-nav-container {
    border-right: 1px solid var(--border-secondary);
    height: 100%;
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
    overflow-x: hidden;
}

.nav-item {
    padding: 10px 18px;
    font-size: 11pt;
    margin: 3px 10px;
    border-radius: 8px;
}

.nav-item-minimized{
    justify-content: center;
}

.nav-label{
    padding-left: 8px;
}

.nav-item:hover{
    cursor: pointer;
    background-color: var(--background-secondary);
}

.mobile-side-nav-container{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    background-color: var(--background-primary);
    position: fixed;
    bottom: 0;
    width: 100vw;
    z-index: 100;
    border-top: 1px solid var(--border-secondary);
}

.mobile-nav-item {
    padding: 14px 18px;
    font-size: 13pt;
}
</style>
