<template>
<div class="nav-bar flex-row justify-evenly">
  <div v-for="(option, index) in options" :key="index">
    <div class="nav-item" 
      :class="{'selected': selected == option.label}"
      @click="_optionChanged(option)">
      <div class="font-bold">{{ option.label }}</div>
      <div v-if="option.subLabel">{{ option.subLabel }}</div>
    </div>
  </div>
</div>
</template>
  
<script>
export default {
  props: {
    options: Array,
  },

  data() {
    return {
        selected: null
    }
  },

  mounted: function() {
    this.selected = this.options[0].label
  },

  methods: {
    _optionChanged(option){
      this.selected = option.label;
      this.$emit('onChange', option);
    }
  },
};
</script>

<style scoped>
.nav-bar {
  padding: 4px 6px;
  background-color: var(--background-secondary);
  border: 1px solid var(--border-secondary);
  border-radius: 12px;
}

.nav-item{
  padding: 8px 20px;
  border-radius: 8px;
  text-align: center;
}
.nav-item:hover {
  cursor: pointer;
  background-color: var(--background-tertiary);
}
.nav-item.selected {
  background-color: var(--primary-color);
  border: 1px solid var(--primary-color-dark);
  font-weight: 400;
  color: white;
}

</style>
  