import { render, staticRenderFns } from "./TransactionsView.vue?vue&type=template&id=442a5d71&scoped=true&"
import script from "./TransactionsView.vue?vue&type=script&lang=js&"
export * from "./TransactionsView.vue?vue&type=script&lang=js&"
import style0 from "./TransactionsView.vue?vue&type=style&index=0&id=442a5d71&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "442a5d71",
  null
  
)

export default component.exports