<template>
<div>

  <vc-donut class="pie-chart"
    background="var(--background-primary)" 
    foreground="var(--background-secondary)"
    :size="11" unit="em" :thickness="25"
    :start-angle="0" 
    :auto-adjust-text-size="true"
    :sections="sections"
    :total="total"
    >
    <h2>{{ formatMoney(total / 100) }}</h2>
  </vc-donut>

  <ChipSelector @onChange="_handlePerspectiveChanged" :options="[
      {label: 'Budgeted', subLabel: formatMoney(totalBudgeted() / 100.0)},
      {label: 'Spent', subLabel: formatMoney(totalSpent()/ 100.0)},
      {label: 'Remaining', subLabel: formatMoney(totalRemaining()/ 100.0)}
  ]"></ChipSelector>

  <div class="table">
      <div v-for="category in sortedCategories" :key="category.name" class="row">
          <div class="cell large">
              <i class="fas fa-square" :style="{'color': colorMap[category.name]}"></i>
              &nbsp;&nbsp;
              <span class="link-button" @click="openTransactions(category)">
                {{category.name}}
              </span>
          </div>
          <!-- BUDGETED -->
          <div v-if="perspective == 'Budgeted'" class="cell small">
              {{formatMoney( totalBudgetedCategory(category) / 100)}}
          </div>
          <div v-if="perspective == 'Budgeted'" class="cell small">
              {{formatPercent( totalBudgetedCategory(category) / totalBudgeted())}}
          </div>

          <!-- SPENT -->
          <div v-if="perspective == 'Spent'" class="cell small">
              {{formatMoney( totalSpentCategory(category) / 100)}}
          </div>
          <div v-if="perspective == 'Spent'" class="cell small">
              {{formatPercent( totalSpentCategory(category) / totalSpent() )}}
          </div>

          <!-- REMAINING -->
          <div v-if="perspective == 'Remaining'" class="cell small">
              {{formatMoney( totalRemainingCategory(category) / 100)}}
          </div>

          
      </div>
  </div>
</div>
</template>

<script>
import FormatUtils from '../../utilities/FormatUtils'
import ChipSelector from '../ChipSelector';
import BudgetPieChart from '../dashboard/BudgetPieChart.vue';

export default {
  mixins: [FormatUtils],

  components: {
    ChipSelector,
    BudgetPieChart,
  },

  props: {
    budget: Object,
  },

  data() {
    return {
        perspective: "Budgeted",

    }
  },

  computed: {
    // Sort the budget categories based on perspective
    sortedCategories: function() {
      var sorted = structuredClone(this.budget.categories)

      if (this.perspective == "Budgeted") {
        sorted.sort((a,b) => this.totalBudgetedCategory(b) - this.totalBudgetedCategory(a))
      } else if (this.perspective == "Spent") {
        sorted.sort((a,b) => this.totalSpentCategory(b) - this.totalSpentCategory(a))
      } else {
        sorted.sort((a,b) => this.totalRemaining(b) - this.totalRemaining(a))
      }

      return sorted
    },

    // pie-chart sections based on perspective
    sections: function() {
      var sections = [];
      
      var i = 0; // color index

      if (this.perspective == "Remaining"){
        return []
      }
      
      for (var category of this.sortedCategories) {
        var section = {};
        section.label = category.name;

        if (this.perspective == "Budgeted") {
          section.value = this.totalBudgetedCategory(category);
        } else if (this.perspective == "Spent") {
          section.value = this.totalSpentCategory(category);
        }

        section.color = this.colorMap[category.name]

        sections.push(section);
      }


      return sections;
    },

    total: function(){
      // pie-chart sections based on perspective
      if (this.perspective == "Budgeted"){
        return this.totalBudgeted();
      } else  if (this.perspective == "Spent"){
        return this.totalSpent();
      } else {
        return this.totalRemaining();
      }
    },

    colorMap: function(){
      var colors = [
        "#6d4aff","#1dbf7b","#3691ef","#d76a59", 
        "#efae38", "#fa8231", "#778ca3", "#2bcbba"
      ];
      var map = {};
      var i = 0; // to track color index
      
      for (var category of this.budget.categories) {
        // Cycle through colors array
        map[category.name] = colors[i++ % colors.length]; 
      }

      console.log("color map:" + JSON.stringify(map))
      
      return map
    },

  },


  methods: {

    openTransactions: function(category){
      var filterOptions = {
        "category": category.name,
      }
      this.$emit('open-transactions', filterOptions);
    },

    totalSpentCategory: function(category){
      return category.sub_categories.reduce((total, sub_category) => {
        return total + sub_category.amount_spent;
      }, 0);
    },

    totalBudgetedCategory: function(category){
      return category.sub_categories.reduce((total, sub_category) => {
        return total + sub_category.amount_budgeted;
      }, 0);
    },

    totalRemainingCategory: function(category){
      return this.totalBudgetedCategory(category) - this.totalSpentCategory(category);
    },

    totalSpent: function(){
      let total = 0;
      for(let category of this.budget.categories){
        for (let sub_category of category.sub_categories){
          total += sub_category.amount_spent;
        }
      }
      return total;
    },

    totalBudgeted: function(){
      let total = 0;
      for(let category of this.budget.categories){
        for (let sub_category of category.sub_categories){
          total += sub_category.amount_budgeted;
        }
      }
      return total;
    },

    totalRemaining: function(){
      return this.totalBudgeted() - this.totalSpent();
    },

    // event handlers
    _handlePerspectiveChanged: function(option){
        console.log("Per Changed: " + JSON.stringify(option));
        this.perspective = option.label;
    }

  }
}
</script>

<style scoped>

.pie-chart {
  margin-bottom: 1em;
}

.table {
  padding-top: 1em;
  display: flex;
  flex-direction: column;
}

.row {
  display: flex;
  width: 100%;
}

.cell {
  padding: 10px;
  border-bottom: 1px solid var(--border-secondary)
}

.large {
  flex: 3; /* First column takes twice the space */
}

.small {
  flex: 1; /* Second and third columns take equal space */
}

</style>
