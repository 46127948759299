<template>
    <select ref='input' class='form-input bread-crumb-select' @change='$emit("change")'>
        <option v-for='option in options' :key="option.value" :value='option.value'>
            {{ option.label }}
        </option>
    </select>
</template>

<script>

export default {

  data() {
      return {
          options: Array
      }
  },

  methods: {
      getValue: function() {
          return this.$refs.input.value;
      },
      setValue: function(value) {
          this.$refs.input.value = value;
      }
  }
}
</script>

<style scoped>

.bread-crumb-select{
    font-size: 12pt;
    min-width: 12em;
    margin: 10px;
    padding-right: 10px
}

</style>